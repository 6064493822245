import { Box, Container, Button, styled } from '@material-ui/core'
import { navigate, PageProps } from 'gatsby'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckIcon from '@mui/icons-material/Check'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import axios from 'axios'

import PageHeader from '../../../components/page-header'
import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'
import { useCart } from '../../../hooks/useCart'
import TtrLogoYellow from '../../../images/registration/ttr.png'
import { decryptData } from '../../../utils/crypto'

const StyledSlideParent = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        height: '400px',
      },
    }
  }}
  height: 450px;
  box-shadow: 0px 10px 20px 0px #ddd;
  background-color: #ececec;
  border-radius: 10px;
`

const StyledSlideItem = styled('div')`
  height: inherit;
  width: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledSlideArrowBack = styled('div')`
  float: left;
  font-weight: bold;
  font-size: 20px;
  width: 10%;
  text-align: left;
`

const StyledSlideArrowForward = styled('div')`
  float: right;
  font-weight: bold;
  font-size: 20px;
  width: 10%;
  text-align: right;
`

const StyledSlideText = styled('div')`
  float: left;
  font-weight: bold;
  font-size: 20px;
  margin-top: 4px;
  width: 80%;
  text-align: center;
`

const StyledSlideActionsContainer = styled(Box)`
  margin-top: 30px;
`

const StyledColorPickerLabel = styled('div')`
  font-size: 20px;
  font-weight: bold;
  color: #008174;
  margin-bottom: 10px;
  text-align: left;
`

const FloatFixer = styled('div')`
  clear: both;
`

const colorPickerItemStyles = {
  height: '50px',
  width: '50px',
  border: '1px solid black',
  borderRadius: '6px',
  margin: '0 5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const StyledUniformImageContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        float: 'none',
        width: '100%',
      },
    }
  }}

  float: left;
  width: 50%;
`

const StyledColorPickerContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        padding: 0,
        width: '100%',
        marginBottom: '60px',
      },
    }
  }}
  text-align: left;
  float: left;
  width: 50%;
  padding: 0 40px;
`
const StyledColorsBox = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        float: 'none',
        width: '100%',
        marginTop: '20px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }
  }}

  margin-top: 1.5rem;
  display: flex;
`

const StyledPrimaryButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        margin: 'auto',
        marginTop: '2rem',
      },
    }
  }}
  background-color: #008174;
  font-weight: bold;
  margin-top: 30px;
  display: block;
  width: 300px;
  padding: 10px;
`

const StyledImg = styled('img')`
  margin-top: -8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledYellowTtrLogo = styled('img')`
  padding-top: 1rem;
  padding-left: 1rem;
  height: 55px;
`

const RegisterTeamUniformDesignPage = (props: PageProps) => {
  const cartType = 'team'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  const [slideName, setSlideName] = useState('Front')
  const [shirtColor, setShirtColor] = useState('white')
  const [sideColor, setSideColor] = useState('white')
  const [backColor, setBackColor] = useState('white')

  const { cart, isCartLoading, setTeamUniformDesign } = useCart({
    type: cartType,
  })

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.competition) {
      navigate(`/register/team/venue`)
    }
  }, [cart?.data?.competition, isCartLoading])

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.teamDetails) {
      navigate(`/register/team/details`)
    }
  }, [cart?.data?.teamDetails, isCartLoading])

  useEffect(() => {
    if (cart?.data?.teamUniformDesign) {
      setShirtColor(cart?.data?.teamUniformDesign?.front)
      setSideColor(cart?.data?.teamUniformDesign?.side)
      setBackColor(cart?.data?.teamUniformDesign?.back)
    }
  }, [cart?.data?.teamUniformDesign])

  const handleSlideNext = () => {
    switch (slideName) {
      case 'Front':
        setSlideName('Sides')
        break
      case 'Sides':
        setSlideName('Back')
        break
      case 'Back':
        setSlideName('Front')
        break

      default:
        setSlideName('Front')
        break
    }
  }

  const handleSlidePrevious = () => {
    switch (slideName) {
      case 'Front':
        setSlideName('Back')
        break
      case 'Back':
        setSlideName('Sides')
        break
      case 'Side':
        setSlideName('Front')
        break

      default:
        setSlideName('Front')
        break
    }
  }

  const handleChangeColor = (color: string) => {
    switch (slideName) {
      case 'Front':
        setShirtColor(color)
        break
      case 'Sides':
        setSideColor(color)
        break
      case 'Back':
        setBackColor(color)
        break

      default:
        setSlideName('Front')
        break
    }
  }

  const onSubmit = async () => {
    await setTeamUniformDesign({
      teamUniformDesign: {
        front: shirtColor,
        side: sideColor,
        back: backColor,
      },
      type: cartType,
    }).unwrap()

    navigate('/register/team/review')
  }

  const checkUniformAndSubmit = async () => {
    const competitionId = cart?.data?.competition?._id
    try {
      const res = await axios.post(
        `https://api.tritagrugby.com/competition/uniform-validator`,
        {
          competitionId,
          colourWay: {
            front: shirtColor,
            side: sideColor,
            back: backColor,
          },
        },
        {
          headers: {
            // this is only a test token
            mahanginako: decryptData(localStorage.getItem('mahiwagangTanzan')),
          },
        },
      )

      if (res) {
        if (res.data) {
          if (res.data.isUsed) {
            if (
              window.confirm(
                'A team in your competition already has this design. Do you wish to continue?',
              )
            ) {
              onSubmit()
            }
          } else {
            onSubmit()
          }
        }
      }
    } catch (error) {
      alert('Something went wrong, Please try again.')
    }
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <Container
        sx={{
          marginTop: '60px',
        }}
      >
        <Box
          sx={{
            marginTop: '20px',
          }}
        >
          <PageHeader title="Uniform Design" />
        </Box>
        <StyledUniformImageContainer>
          <StyledSlideParent>
            <StyledYellowTtrLogo alt="" src={TtrLogoYellow} />
            {slideName === 'Front' && (
              <StyledSlideItem>
                <StyledImg
                  alt=""
                  src={
                    require(`../../../images/uniforms/shirts/shirt-front-${shirtColor}-${sideColor}.png`)
                      .default
                  }
                />
              </StyledSlideItem>
            )}
            {slideName === 'Sides' && (
              <StyledSlideItem>
                <StyledImg
                  alt=""
                  src={
                    require(`../../../images/uniforms/shirts/shirt-side-${shirtColor}-${sideColor}-${backColor}.png`)
                      .default
                  }
                />
              </StyledSlideItem>
            )}
            {slideName === 'Back' && (
              <StyledSlideItem>
                <StyledImg
                  alt=""
                  src={
                    require(`../../../images/uniforms/shirts/shirt-back-${sideColor}-${backColor}.png`)
                      .default
                  }
                />
              </StyledSlideItem>
            )}
          </StyledSlideParent>
          <StyledSlideActionsContainer>
            <StyledSlideArrowBack
              onClick={() => {
                handleSlidePrevious()
              }}
            >
              <ArrowBackIcon />
            </StyledSlideArrowBack>
            <StyledSlideText>{slideName}</StyledSlideText>
            <StyledSlideArrowForward
              onClick={() => {
                handleSlideNext()
              }}
            >
              <ArrowForwardIcon />
            </StyledSlideArrowForward>
            <FloatFixer />
          </StyledSlideActionsContainer>
        </StyledUniformImageContainer>
        <StyledColorPickerContainer>
          <StyledColorPickerLabel>Select colour</StyledColorPickerLabel>
          <StyledColorsBox>
            <div
              style={{ ...colorPickerItemStyles, backgroundColor: '#fff' }}
              onClick={e => {
                e.preventDefault()
                handleChangeColor('white')
              }}
            >
              {slideName === 'Front' && shirtColor === 'white' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Sides' && sideColor === 'white' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Back' && backColor === 'white' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
            </div>

            <div
              style={{ ...colorPickerItemStyles, backgroundColor: '#3c3835' }}
              onClick={e => {
                e.preventDefault()
                handleChangeColor('charcoal')
              }}
            >
              {slideName === 'Front' && shirtColor === 'charcoal' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Sides' && sideColor === 'charcoal' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Back' && backColor === 'charcoal' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
            </div>
            <div
              style={{ ...colorPickerItemStyles, backgroundColor: '#008174' }}
              onClick={e => {
                e.preventDefault()
                handleChangeColor('jade')
              }}
            >
              {slideName === 'Front' && shirtColor === 'jade' && (
                <CheckIcon sx={{ color: '#fff', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Sides' && sideColor === 'jade' && (
                <CheckIcon sx={{ color: '#fff', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Back' && backColor === 'jade' && (
                <CheckIcon sx={{ color: '#fff', fontSize: '2.5rem' }} />
              )}
            </div>
            <div
              style={{ ...colorPickerItemStyles, backgroundColor: '#f5821f' }}
              onClick={e => {
                e.preventDefault()
                handleChangeColor('orange')
              }}
            >
              {slideName === 'Front' && shirtColor === 'orange' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Sides' && sideColor === 'orange' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Back' && backColor === 'orange' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
            </div>
            <div
              style={{ ...colorPickerItemStyles, backgroundColor: '#c4037f' }}
              onClick={e => {
                e.preventDefault()
                handleChangeColor('plum')
              }}
            >
              {slideName === 'Front' && shirtColor === 'plum' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Sides' && sideColor === 'plum' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
              {slideName === 'Back' && backColor === 'plum' && (
                <CheckIcon sx={{ color: '#008174', fontSize: '2.5rem' }} />
              )}
            </div>
          </StyledColorsBox>
          <StyledPrimaryButton
            variant="contained"
            onClick={checkUniformAndSubmit}
          >
            FINISH
            <ArrowForwardIcon
              style={{
                fontSize: '16px',
                marginLeft: '10px',
              }}
            />
          </StyledPrimaryButton>
        </StyledColorPickerContainer>
        <FloatFixer />
      </Container>
    </>
  )
}

export default withAuthenticationRequired(RegisterTeamUniformDesignPage)
